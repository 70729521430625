import React from 'react';
import './HeroSection.css'
import { HeroSectionProps } from '../../models/HeroSectionProps';

const HeroSection: React.FC<HeroSectionProps> = ({Top,Hedding,ShowButton,Link,Button}) => {
  return (
    <div className='hero-section hero-section-backgroud position-relative w-100'>
      <div className='hero-section-overlayer'>
        <div className='position-absolute text-white d-flex flex-column align-items-center justify-content-center hero-section-content text-center text-uppercase'>
                <h1 className='lead text-primary fw-bolder'>{Top}</h1>
                <p className='display-4 text-white fw-bold' dangerouslySetInnerHTML={{ __html:  Hedding}}>
                </p>
                { ShowButton ? <a href={Link} className='btn btn-primary py-3 text-black fw-bold'>{Button}</a> : null }
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
