import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import env from '../env';

const rankImageArray = [
  { type: "Iron 1", image: "https://cdn3.emoji.gg/emojis/3624-valorant-iron-1.png" },
  { type: "Iron 2", image: "https://cdn3.emoji.gg/emojis/7351-valorant-iron-2.png" },
  { type: "Iron 3", image: "https://cdn3.emoji.gg/emojis/1854-valorant-iron-3.png" },

  { type: "Bronze 1", image: "https://cdn3.emoji.gg/emojis/4159-valorant-bronze-1.png" },
  { type: "Bronze 2", image: "https://cdn3.emoji.gg/emojis/4376-valorant-bronze-2.png" },
  { type: "Bronze 3", image: "https://cdn3.emoji.gg/emojis/4590-valorant-bronze-3.png" },

  { type: "Silver 1", image: "https://cdn3.emoji.gg/emojis/6335-valorant-silver-1.png" },
  { type: "Silver 2", image: "https://cdn3.emoji.gg/emojis/8138-valorant-silver-2.png" },
  { type: "Silver 3", image: "https://cdn3.emoji.gg/emojis/3293-valorant-silver-3.png" },

  { type: "Gold 1", image: "https://cdn3.emoji.gg/emojis/5533-valorant-gold-1.png" },
  { type: "Gold 2", image: "https://cdn3.emoji.gg/emojis/2060-valorant-gold-2.png" },
  { type: "Gold 3", image: "https://cdn3.emoji.gg/emojis/3293-valorant-gold-3.png" },

  { type: "Platinum 1", image: "https://cdn3.emoji.gg/emojis/4590-valorant-platinum-1.png" },
  { type: "Platinum 2", image: "https://cdn3.emoji.gg/emojis/3255-valorant-platinum-2.png" },
  { type: "Platinum 3", image: "https://cdn3.emoji.gg/emojis/5816-valorant-platinum-3.png" },

  { type: "Diamond 1", image: "https://cdn3.emoji.gg/emojis/4590-valorant-diamond-1.png" },
  { type: "Diamond 2", image: "https://cdn3.emoji.gg/emojis/3939-valorant-diamond-2.png" },
  { type: "Diamond 3", image: "https://cdn3.emoji.gg/emojis/6354-valorant-diamond-3.png" },

  { type: "Ascendant 1", image: "https://cdn3.emoji.gg/emojis/4590-valorant-ascendant-1.png" },
  { type: "Ascendant 2", image: "https://cdn3.emoji.gg/emojis/8376-valorant-ascendant-2.png" },
  { type: "Ascendant 3", image: "https://cdn3.emoji.gg/emojis/2309-valorant-ascendant-3.png" },

  { type: "Immortal 1", image: "https://cdn3.emoji.gg/emojis/1518-valorant-immortal-1.png" },
  { type: "Immortal 2", image: "https://cdn3.emoji.gg/emojis/1775-valorant-immortal-2.png" },
  { type: "Immortal 3", image: "https://cdn3.emoji.gg/emojis/5979-valorant-immortal-3.png" },

  { type: "Radiant 1", image: "https://cdn3.emoji.gg/emojis/5979-valorant-radiant.png" },
];

const StreamOverlayer:  React.FC = () => {
  const [rankData, setRankData] = useState<string>("");
  const [rankRR, setRankRR] = useState<string>("");
  const [colorCode, setColor] = useState<string>("#000");

  var id = useParams();

  useEffect(() => {
    const fetchRank = async () => {
      try {
      
        const response = await axios.get(`${env.urlApi}/api/rank/member/${id["id"]}`);

        if(response.data.indexOf('many') >= 0) return;

        const rankSplit = response.data ? response.data.split("with") : [];
        setRankData(rankSplit[0].trim());
        setRankRR(rankSplit[1].trim());      
               
      } catch (error) {
        console.error('Error fetching rank data:', error);
      }
    };

    fetchRank();

    const intervalId = setInterval(fetchRank, 30000);

    if(id["color"] != null)
        setColor('#'+id["color"] as string);
        console.log(id);

    return () => clearInterval(intervalId);
  }, [id]);

  return (
    <div style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      {rankData !== "" ? (
       <div style={{ display: "flex", alignItems: "center" }}>
          <img src={rankImageArray.find((element) => element.type === rankData)?.image} alt={rankData} style={{ width : "50px"}}  />
          <div style={{ marginLeft: "10px", color: colorCode }}>
            {rankData} <br />
            {rankRR}
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default StreamOverlayer;