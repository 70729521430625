import { AgreementSection } from "./AgreementSection";

const sections: AgreementSection[] = [
    {
        title:"Agreement Position",
        content:"This Agreement does not create any partnership, joint venture, employment or agency between you, us or Impact. You are, and shall remain during the term of the Agreement, a completely independent contractor. That means that you can’t bind us to do or not to anything which isn’t stated in this Agreement. (Sorry.)"
    },
    {
        title: "Collection of Personal Information",
        content: "By becoming a Member of ACID, you agree to provide us with your full name, national identification card number, phone number, email address, and home address etc. for record-keeping purposes."
    },
    {
        title: "Acceptance",
        content: "By indicating acceptance of this Agreement by click-through or opt-in, you submit an authorised electronic signature. You accept that you can’t subsequently object to this Agreement on the basis that it was not physically/ ‘wet’ signed."
    },
    {
        title: "EIO",
        content: "We can change the terms of an EIO at any time (by replacing it with another EIO) depending on your performance against Targets. If you out-perform your Targets, you may be moved to a higher Tier involving enhanced Benefits. If you under-perform, you may be moved to a lower Tier with lesser Benefits."
    },
    {
        title: "Use of ACID Name and Logo",
        content: "As a Member of ACID, you agree to use the #AcidCommunity hashtag in any videos that you create, and to provide at least one video for the ACID YouTube channel as scheduled by Leadership Additionally, you agree to include the ACID name within your game name on PUBG Mobile and Discord, using the format 'ACID↬Name'. For other games, you agree to add the ACID name within brackets before your name, such as [ACID] Name and of Game as hash tag then it must be an ACID."
    },
    {
        title: "Code of Conduct",
        content: "As a Member of ACID, you agree to be respectful to all members of the community and to follow and subscribe to ACID's social media pages. You also agree to refrain from hate speech, bullying, or harassment. If you have any issues with a colleague, you can bring it to the attention of the LAW Enforcement Board for resolution."
    },
    {
        title: "Use of Discord",
        content: "As a Member of ACID, you agree to join the ACID Discord server and to include the ACID name with your own name. Any disputes or fights between members are to be limited to the Clan/Squad channels."
    },
    {
        title: "LAW Enforcement Board",
        content: "The LAW Enforcement Board is responsible for resolving any issues that may arise between members of ACID. If a member cannot follow this Agreement, the Board has the authority to impose any type of penalty deemed appropriate."
    },
    {
        title: "Intellectual Property",
        content: "All content published on ACID's social media and YouTube channels is the property of ACID. You agree not to use this content for any commercial purposes without the express written permission of ACID. ACID acquires rights in any content generated by members and may use it for various purposes. Members acknowledge existing Intellectual Property rights and agree that ACID does not acquire rights to their pre-existing Intellectual Property."
    },
    {
        title: "License for Use of Creative",
        content: "Members are granted a license to use the Creative solely for ACID-related obligations."
    },
    {
        title: "Data Privacy",
        content: "ACID respects your privacy and will only use your personal information for the purposes of running and maintaining the community."
    },
    {
        title: "Governing Law and Venue",
        content: "This Agreement and any disputes arising from it will be governed by the laws of Pakistan. The venue for any legal proceedings will be Pakistan."
    },
    {
        title: "Prohibited Content",
        content: "You agree not to publish any content that promotes, encourages, coordinates, or provides instructions for suicide, self-harm, or eating disorders. You also agree not to promote any illegal activities."
    },
    {
        title: "Leadership",
        content: "Muhammad Rizwan is the top leader of ACID, with Muhammad Ali as the second in command. The leaders have the final say in all matters related to the community, and their decisions are binding for all members."
    },
    {
        title: "Use of Member Content",
        content: "By becoming a Member of ACID, you grant the community a perpetual, irrevocable, worldwide, non-exclusive, and royalty-free license to use any content that you publish on the ACID official channel or social media pages for the purpose of generating revenue. ACID is not liable to pay any compensation or royalties to you for the use of your content."
    },
    {
        title: "Confidentiality",
        content: "As a Member of ACID, you agree not to disclose any confidential or proprietary information of the community to any third party, including but not limited to information about ACID's operations, policies, members, or financial matters. This obligation will continue even after you leave the community. You also agree not to engage in any activities that may harm the reputation or interests of ACID."
    },
    {
        title: "Use of Third-Party Tools and Cheat Tools",
        content: "As a Member of ACID, you agree not to use any third-party tools or cheat tools while participating in any activities related to the community. The use of such tools is strictly prohibited and may result in disciplinary action, up to and including termination of your membership."
    },
    {
        title: "Other Agreements",
        content: "As a Member of ACID, you agree not to enter into any agreements with other communities or marketing agencies that may conflict with your obligations to the community. You also agree to prioritize your commitments to ACID over any other commitments. Any violation of this provision may result in disciplinary action, up to and including termination of your membership."
    },
    {
        title: "Attendance at Meetings and Events",
        content: "As a Member of ACID, you agree to attend meetings and events called by the leadership of the community, unless you have a valid reason for not being able to attend. Failure to attend may result in disciplinary action, up to and including termination of your membership."
    },
    {
        title: "Termination",
        content: "This Agreement may be terminated by ACID at any time, with or without cause, by providing written notice to the other party. Termination may occur under the following circumstances:<br />(a) Under-performance: This is where a Target is set in the lowest Tier of EIO and you fail to meet it (e.g., because you're playing/practicing).<br />(b) Mutual Agreement: We both decide to go our separate ways.<br />(c) Cause: This is where you do something which breaches the terms of this Agreement - for example, breaching the ACID community policy.<br />Upon termination, all rights and obligations of the parties under this Agreement will cease, except for those rights and obligations that by their nature are intended to survive termination. In the event of termination, ACID may continue to use any content that you published on the ACID official channel or social media pages, as provided in Point 13 of this Agreement."
    },
    {
        title: "Modifications",
        content: "This Agreement may be modified by ACID at any time, with or without notice to the Member. Any modifications will be effective immediately upon posting on the ACID website or upon notification to the Member. The Member's continued membership in the community after such posting or notification will constitute the Member's acceptance of the modifications. If the Member does not agree to the modifications, the Member may terminate their membership in accordance with Point 18 of this Agreement."
    },
    {
        title: "ACID Social Media and YouTube",
        content: "The ACID social media accounts and YouTube channel are the property of the ACID Gaming Community. The community has the right to change the content, format, or focus of these platforms at any time, with or without notice to the members. The members agree to support and promote the ACID social media and YouTube channel as part of their membership in the community."
    },
    {
        title: "Change in Leadership",
        content: "The leadership of ACID may be changed by the current leaders at any time, with or without notice to the members. The current leaders may also transfer their leadership roles to other individuals or organizations, such as a presidential body or board of directors. The members agree to support and abide by the decisions of the new leadership."
    },
    {
        title: "Account Registration and Main Account Change",
        content: "Each member of ACID agrees to register their main gaming account as well as any alternate accounts they may use. The main account registered at the time of joining ACID will be considered the primary account for the duration of the membership. Any changes to the main account during the membership period must be communicated to the leadership for approval. Members may request a change to their main account for valid reasons such as loss of access, account suspension, or transitioning to a new primary gaming account. The decision to approve the change of main account rests with the leadership, and such requests will be evaluated on a case-by-case basis. Members are required to provide sufficient justification for the change, and any decision made by the leadership in this regard is final. Failure to adhere to this policy may result in disciplinary action as outlined in this Agreement."
    },   
];

export default sections;
