import React, { useState } from 'react';

const LeftUserCard: React.FC<any> = ({ user }) => {

    return (
        <>
            <div className="card-body mt-5">
                <div className="d-flex flex-column align-items-center text-center">
                    <img src={`https://cdn.discordapp.com/avatars/${user.user.id}/${user.user.avatar}.png`} alt="Admin" className="rounded-circle p-1 bg-white" width="110" />
                    <div className="mt-3">
                        <h4 className="text-white">{user.user.username}</h4>
                    </div>
                </div>
                <ul className="list-group list-group-flush mt-3 ms-2">
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">ACID Member</h6>
                        <span className="fw-bold">{user.isMember ? "Yes" : "No"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">Content Creater</h6>
                        <span className="fw-bold">{user.isContentCreater ? "Yes" : "No"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">E-Mail</h6>
                        <span className="fw-bold">{user.user.email}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">E-Sport</h6>
                        <span className="fw-bold">{user.eSport ? "Yes" : "No"}</span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default LeftUserCard;
