import { useOutletContext } from "react-router-dom";
import { SectionCounter } from "../../models/SectionCounter";
import Section from "../../components/Section/Section";

const Dashboard: React.FC = () => {
  const [member, setMember, counter]: any = useOutletContext();

  return (
    <Section counter={counter.MySectionCounter} heading="Welcome to ACID X Studio" subHeading="" classNames="vh-100">
    </Section>
  );
};

export default Dashboard;