'use client'
import React from 'react';
import './FooterSection.css';
import { useData } from '../../../Provider/DataContext';

const FooterSection: React.FC = () => {
    const { data } = useData();
    return (
        <div className="footer">
            <hr />
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xs-12 about-company">
                        <h2>ACID Community</h2>
                        <p className="pr-5 text-white-50">{data?.Footer.FooterAbout}</p>
                        <p><a href="/"><i className="fa fa-facebook-square mr-1"></i></a><a href="/"><i className="fa fa-linkedin-square"></i></a></p>
                    </div>
                    <div className="col-lg-3 col-xs-12 links">
                        <h4 className="mt-lg-0 mt-sm-3">Links</h4>
                        <ul className="m-0 p-0">
                            {data?.Footer.Links.map((probs: any, index: number) => (
                                <li key={index}>- <a href={probs.Link}>{probs.Name}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-4 col-xs-12 location">
                        <h4 className="mt-lg-0 mt-sm-4">Location</h4>
                        <p>{data?.About.Location}</p>
                        <p><i className="fa fa-envelope-o mr-3"></i>{data?.About.Email}</p>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col copyright">
                        <p className=""><small className="text-white-50">© 2017-{(new Date()).getFullYear()}. All Rights Reserved.</small></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterSection;
