import CryptoJS from "crypto-js";
import env from '../env';

export var MemberData = null;

export const LoginFormLocalStorage = () => {
    const storedData = localStorage.getItem('permissions');
    const jsonString = CryptoJS.AES.decrypt(storedData ?? '', env.secretKey).toString(CryptoJS.enc.Utf8);
    MemberData = jsonString == "" || jsonString == null ? null : JSON.parse(jsonString)
}

export const LoginFormAuth = (data: any) => {
    if (data === "") return;
    MemberData = data;
    const aes = CryptoJS.AES.encrypt(JSON.stringify(MemberData), env.secretKey).toString();
    localStorage.setItem('permissions', aes);
}

export const Logout = () => {
    localStorage.removeItem('permissions');
}