import React from 'react';
import { ArticleProps } from '../../../models/ArticleProps';
import { useData } from '../../../Provider/DataContext';

const ArticleSection: React.FC = () => {

  const { data } = useData();

  return (
    <div className="container my-5 text-uppercase">
      <div className="row justify-content-center">
        <div className="col-10 ">
          <div className="row">
            <div className="col-6 align-self-center">
              <h6 className='text-primary'>Blog</h6>
              <h2>Articles</h2>
            </div>
            <div className="col-6 text-end align-self-center">
              <a href={data?.Home.Articals.SeeAll.Link} className="btn btn-primary text-dark">{data?.Home.Articals.SeeAll.Text}</a>
            </div>
          </div>

          <div className="row mt-4">
            {data?.Home.Articals.List.map((probs: any, index: number) => (
              <div key={index} className="col-3 align-self-center">
                <a href={probs.Link} className="text-white" target='_blank' rel="noreferrer">
                  <img src={probs.Image} alt={probs.Title} className="img-fluid" />
                  <div className="row my-2">
                    <div className="col align-self-center">
                      {probs.Tags?.map((tag: string, index: number) => (
                        <span key={index} className="badge border border-primary me-2">{tag}</span>
                      ))}
                    </div>
                    <div className="col-6 align-self-center text-end">
                      <span className="fs-6 text-secondary">{probs.Timestemp}</span>
                    </div>
                  </div>
                  <p className='fs-5'>{probs.Title}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleSection;
