import { Link, Outlet, useParams } from "react-router-dom"
import { LoginFormAuth, LoginFormLocalStorage, MemberData } from "../../Provider/MemberContext"
import { useEffect, useState } from "react";
import axios from "axios";
import env from "../../env";
import NavigationBar from "../../components/NavigationBar";
import { DataProvider } from "../../Provider/DataContext";
import { SectionCounter } from "../../models/SectionCounter";
import Section from "../../components/Section/Section";
import FooterSection from "../../components/Section/FooterSection/FooterSection";
import HorizontalJoinUs from "../../components/Section/HorizontalJoinUs/HorizontalJoinUs";
import LeftUserCard from "../../components/Member/LeftUserCard";

LoginFormLocalStorage()

const DoWork = async (params: any) => {

    if (MemberData != null && MemberData !== "") return MemberData;

    var data = null;
    if (env.isDebug && true) {
        data = JSON.parse(`{"user":{"id":"454651687559495680","username":"2thoughts","avatar":"a_57c84829075143a36ea3827bd360c8c4","discriminator":"0","public_flags":64,"flags":64,"banner":"a_804c70ca545fedda6f60ce4a7d4d0d1b","accent_color":null,"global_name":"2Thoughts","avatar_decoration_data":{"asset":"a_fed43ab12698df65902ba06727e20c0e","sku_id":"1144058844004233369"},"banner_color":null,"clan":null,"mfa_enabled":true,"locale":"en-US","premium_type":2,"email":"allgame45@hotmail.com","verified":true},"username":"2thoughts","discrim":"0","user_id":"454651687559495680","user_avatar":"a_57c84829075143a36ea3827bd360c8c4","isMember":true,"isContentCreater":false}
        `);
    }
    else {
        try {
            const response = await axios.post(`${env.urlApi}/api/permissions`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            data = response.data;
        } catch (error) {
            console.error("Error fetching permissions:", error);
        }
    }
    LoginFormAuth(data);
    return data;
}

const MemberLayout: React.FC<{ routes: any[] }> = ({ routes }) => {

    const [counter] = useState<SectionCounter>(new SectionCounter(0, true));
    const [member, setMember]: any = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchPermissions = async () => {
            const data = await DoWork(params);
            setMember(data);
        };

        if (member == null)
            fetchPermissions();
    }, [params.auth]);

    return member == null || member === "" ? <Link to="/">Click here to Login.</Link> : <div className="overflow-hidden">
        <DataProvider>
            {
                member?.isMember ?
                    <div className="row bg-dark vh-100">
                        <div className="col-2">
                            <LeftUserCard user={member}></LeftUserCard>
                        </div>
                        <div className="col">
                            <NavigationBar routes={routes} hideSocialMedia containerFluid/>
                            <Outlet context={[member, setMember, counter]} />
                        </div>
                    </div>
                    : <Section classNames="row align-items-center vh-75" counter={counter.MySectionCounter}>
                        <HorizontalJoinUs></HorizontalJoinUs>
                    </Section>
            }           
        </DataProvider>
    </div>;
}

export default MemberLayout;