import React from 'react';
import Game from './Game';
import { useData } from '../../../Provider/DataContext';

const UpOrDown = (index : number) : string => {
    return index % 2 === 0 ? "mt-5" : "";
}

const GamesSection: React.FC = () => {
    
  const { data } = useData();

    return (
        <div className='row justify-content-center'>
            {data?.Home?.Games?.List?.map((probs:any, index:any) => (
                <div key={index} className={`col-2 mx-3 ${ `${ UpOrDown(index) }` } `}>
                    <Game name={probs?.Name} background={probs?.BackGround} logo={probs?.Logo}/>
                </div>
            ))}
        </div>
    );
};

export default GamesSection;
