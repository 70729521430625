import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import { MemberRoutes, OtherLayoutRoutes, OtherMemberRoutes, OtherRoutes, Routes as routes } from "./Routes"
import MemberLayout from './pages/member/MemberLayout';
import NotFound from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout routes={routes} />}>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              Component={route.component}
            />
          ))}

          {OtherLayoutRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              Component={route.component}
            />
          ))}
        </Route>
        <Route path="/" element={<MemberLayout routes={MemberRoutes} />}>
          {MemberRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              Component={route.component}
            />
          ))}
          {OtherMemberRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              Component={route.component}
            />
          ))}
        </Route>
        {OtherRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            Component={route.component}
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;