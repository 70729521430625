import React from 'react';
import { useData } from '../../../Provider/DataContext';
import { Link } from 'react-router-dom';

const About: React.FC = () => {
  
  const { data } = useData();

  return (
    <div className='bg-dark text-white'>
      <div className='container'>
        <div className='mx-5 px-5 py-5'>
          <div className='row justify-content-center'>
            <div className='col-6'>
              <div className='me-5'>
                <h3 className='fw-bold text-uppercase'>ABOUT THE <span className='text-primary'>ACID</span> COMMUNIUTY</h3>
                <p>{ data?.Home?.About }</p>
                <Link to="/team" className='btn btn-outline-primary text-uppercase'>MEET THE TEAM</Link>
              </div>
            </div>
            <div className='col-3'>
              <div style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src={ data?.BigLogo } alt='' loading='lazy' width='100%'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
