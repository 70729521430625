import { Outlet } from "react-router-dom"
import NavigationBar from "./components/NavigationBar"
import { DataProvider } from "./Provider/DataContext";
import { useState } from "react";
import { SectionCounter } from "./models/SectionCounter";
import Section from "./components/Section/Section";
import FooterSection from "./components/Section/FooterSection/FooterSection";

const Layout: React.FC<{ routes: any[] }> = ({ routes }) => {
    const [counter] = useState<SectionCounter>(new SectionCounter(0, true));

    return (
        <div className="overflow-hidden">
            <DataProvider>
                <div>
                    <NavigationBar routes={routes} />
                    <Outlet context={[counter]} />
                    <Section counter={counter.MySectionCounter}>
                        <FooterSection />
                    </Section>
                </div>
            </DataProvider>
        </div>
    )
}

export default Layout;