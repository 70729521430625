import ContentCreatorAgreement from './pages/ContentCreatorAgreement';
import Home from './pages/Home';
import Join from './pages/Join';
import MembershipAgreement from './pages/MembershipAgreement';
import Network from './pages/Network';
import StreamOverlayer from './pages/StreamOverlayer';
import Team from './pages/Team';
import Dashboard from './pages/member/Dashboard';
import SubmitUrl from './pages/member/SubmitUrl';
import DuckyDuck from './pages/DuckyDuck';

export const Routes = [
    { path: '/', label: 'Home', component: Home },
    { path: '/team', label: 'Team', component: Team },
    { path: '/content-creator', label: 'Content Creator', component: Network },
    { path: '/about', label: 'About', component: Home },
    { path: '/contact', label: 'Contact', component: Home },
];

export const MemberRoutes = [
    { path: '/member', label: 'Dashboard', component: Dashboard },
    { path: '/share', label: 'Share', component: SubmitUrl },
];

export const OtherMemberRoutes = [
    { path: '/member/:id/:auth', component: Dashboard },
];

export const OtherLayoutRoutes = [
    { path: '/join', component: Join },
    { path: '/membership-agreement', component: MembershipAgreement },
    { path: '/content-creator-agreement', component: ContentCreatorAgreement },
    { path: '/app/duckyduck', component: DuckyDuck },
];

export const OtherRoutes = [
    { path: '/rank/:id/:color?', component: StreamOverlayer },
];
