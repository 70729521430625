import React from 'react';
import './Section.css';
import { SectionProps } from '../../models/SectionProps';

const Section: React.FC<SectionProps> = ({ counter, heading, subHeading, children, classNames }) => {
    return (
        <div className={`${classNames} py-5 ${counter.EvenSection ? "bg-light" : "bg-dark"}`}>
            <div className='container text-white'>
                {heading == null ? '' :
                    <div className='text-center my-3'>
                        <h1 className='fw-bolder'>
                            <span className={`text-uppercase border-4 border-bottom  ${counter.EvenSection ? "border-dark" : "border-primary"}`}>
                                {heading}
                            </span></h1>
                        <p className='mx-auto sub-heading py-3'>{subHeading}</p>
                    </div>
                }
                {children}
            </div>
        </div>
    );
};

export default Section;
