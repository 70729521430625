import React from 'react';
import { TeamProps } from '../../../models/TeamProps';
import { useData } from '../../../Provider/DataContext';

const TeamSection: React.FC = () => {
    
    const { data } = useData();

    return (
        <div className="container overflow-hidden">
            <div className="row gy-4 gy-lg-0 gx-xxl-5 justify-content-center">
            {data?.Team.filter((a :any) => a.HomePage ).sort(function(a :any, b :any){ return a.Position > b.Position }).map((probs: any, index: number) => (
                <div key={index} className="col-12 col-md-6 col-lg-3">
                    <div className="card border-0 shadow-sm overflow-hidden">
                        <div className="card-body p-0">
                            <figure className="m-0 p-0">
                                    <img src={probs.Image} className="img-fluid" loading="lazy" alt={probs.Name + ' ' + probs.designation} />
                                    <figcaption className="bg-light text-white m-0 p-4">
                                        <h4 className="mb-1">{probs.Name}</h4>
                                        <p className="mb-0">{probs.Designation}</p>
                                    </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default TeamSection;
