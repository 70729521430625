import { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import env from "../../env";
import Section from "../../components/Section/Section";

const SubmitUrl: React.FC = () => {

    const [member, setMember, counter]: any = useOutletContext();
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const submissionCount = parseInt(localStorage.getItem("submissionCount") || "0", 10);
    const lastSubmissionTimestamp = parseInt(localStorage.getItem("lastSubmissionTimestamp") || "0", 10);


    useEffect(() => {
        localStorage.setItem("submissionCount", submissionCount.toString());
        localStorage.setItem("lastSubmissionTimestamp", lastSubmissionTimestamp.toString());
    }, [submissionCount, lastSubmissionTimestamp]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYoutubeUrl(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const youtubeRegex = /youtube\.com/;
        if (!youtubeRegex.test(youtubeUrl)) {
            setError("Please enter a valid YouTube URL");
            return;
        }

        const currentTime = Date.now();
        const timeSinceLastSubmission = currentTime - lastSubmissionTimestamp;
        const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;

        if (timeSinceLastSubmission < twentyFourHoursInMillis && submissionCount >= 5) {
            setError("You have reached the maximum submission limit (5) in the last 24 hours.");
            return;
        }

        setError(null);
        setSuccess(null);
        try {

            let data = new FormData();
            data.append('youtubeUrl', youtubeUrl);

            axios.post(`${env.urlApi}/api/postlink`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response: any) => {
                    if (response.data.containsACID) {
                        setSuccess("YouTube title posted on Discord.");
                    } else {
                        setError("YouTube title does not contain #AcidCommunity.");
                    }
                    localStorage.setItem("submissionCount", (submissionCount + 1).toString());
                    localStorage.setItem("lastSubmissionTimestamp", currentTime.toString());
                })
                .catch((error: any) => {
                    setError("An error occurred. Please try again.");
                });

        } catch (error) {
            setError("An error occurred. Please try again.");
        }
    };

    return (
        <>
            {/* <HeroSection Top={""} Hedding="Submit Youtube URL to ACID Discord" ShowButton={false} /> */}
            <Section counter={counter.MySectionCounter} heading="Past Your Link Here" subHeading="" classNames="row h-100 p-5">
                <form onSubmit={handleSubmit} className="my-3">
                    <div className="input-group">
                        <input
                            type="text"
                            value={youtubeUrl}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter YouTube URL"
                        />
                        <button type="submit" className="btn btn-primary text-dark">Submit</button>
                    </div>
                </form>
                {success && <div className="alert alert-success" role="alert"><p className="text-success">{success}</p></div>}
                {error && <div className="alert alert-danger" role="alert"><p className="text-danger">{error}</p></div>}
            </Section>
        </>
    )
}

export default SubmitUrl;
