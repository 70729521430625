import React from 'react';
import './Game.css'
import { GameProps } from '../../../models/GameProps';

const Game: React.FC<GameProps> = ({name,background,logo}) => {
    return (
        <div className='game-height d-flex align-items-start flex-column' style={{
            backgroundImage: `url("${background}")`
        }}>
            <div className="mb-auto p-2"></div>
            <div className="p-2 mb-2 mx-auto">
                <img src={`${logo}`} alt={name} loading='lazy' width={115} height={77} />
            </div>
        </div>
    );
}

export default Game;
