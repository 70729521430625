import React, { useState } from 'react';
import { Logout, MemberData } from '../Provider/MemberContext';
import env from '../env';
import { Link } from 'react-router-dom';

const LoginButton: React.FC = () => {
  const [member]: any = useState(MemberData);

  const url = (): string => {
    return member == null ? `${env.urlApi}/api/login.php` : `${env.url}/member`;
  }

  const logout = () => {
    Logout();
  }

  return (
    <>
      <a className='btn btn-primary text-black text-uppercase' href={url()}>
        {member == null ? "Login" : member?.user?.username}
      </a>
      {member != null ?
        <Link className='btn btn-primary text-black text-uppercase mx-3' to='/' onClick={logout}>
          Logout
        </Link>
        : ""}
    </>
  );
};

export default LoginButton;
