import axios, { Axios } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface DataContextProps {
  children: React.ReactNode;
}

interface DataContextValue {
  data: any | null;
  setData: React.Dispatch<React.SetStateAction<any | null>>;
}

const DataContext = createContext<DataContextValue | undefined>(undefined);

export const DataProvider: React.FC<DataContextProps> = ({ children }) => {
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://raw.githubusercontent.com/ACID-DEVELOPMENT/websitedata/main/data.json');
        const result = await response.data;
        console.log(result.Home.About);
        setData(result);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [data, setData]);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): DataContextValue => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
