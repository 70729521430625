import { AgreementSection } from "./AgreementSection";

const agreementSections: AgreementSection[] = [
    {
        title: "Introduction",
        content: "This agreement (referred to as the 'ACID Community Content Creator Agreement') encompasses the Master Campaign Agreement, all Electronic Insertion Order(s) issued to you (each referred to as an 'EIO'), and the ACID Community Policy.",
    },
    {
        title: "Independent Contractor",
        content: "This agreement does not establish any partnership, joint venture, employment, or agency between you, us, or Impact. You are and shall remain a completely independent contractor throughout the term of this agreement. Therefore, you cannot bind us to any actions not explicitly outlined in this agreement. (Apologies.)",
    },
    {
        title: "Acceptance and Electronic Signature",
        content: "By indicating acceptance of this agreement through click-through or opt-in, you provide an authorized electronic signature. You acknowledge that you cannot subsequently dispute this agreement on the grounds of lacking a physical or 'wet' signature.",
    },
    {
        title: "Targets, Benefits, and Tiers",
        content: "Targets and Benefits are detailed in EIOs. Each Tier corresponds to a different EIO. The issuance of the First EIO will be based on the information provided during your application to join the ACID Community Content Creator Network. If any information is found to be inaccurate, we reserve the right to terminate your participation in the Platform.",
    },
    {
        title: "Performance and Modifications",
        content: "We retain the right to modify the terms of an EIO at any time (by substituting it with another EIO) based on your performance against Targets. Exceeding your Targets may result in advancement to a higher Tier with increased Benefits, while underperformance may lead to demotion to a lower Tier with reduced Benefits.",
    },
    {
        title: "Commencement and Termination",
        content: "This agreement commences upon issuance of the First EIO. You will continue to receive eligible Benefits until termination of this agreement. There are four scenarios under which this agreement may terminate: (a) Expiration, (b) Under-performance, (c) Mutual agreement, and (d) Cause.",
    },
    {
        title: "Termination Consequences",
        content: "Upon termination of this agreement for any reason, your entitlement to use provided materials (the 'Creative'), our Confidential Information, and our Intellectual Property ceases immediately. Benefits provided to you will also cease. If termination occurs due to expiration, under-performance, or mutual agreement, any owed financial amount related to your Benefits will be paid within thirty (30) days. No payment is required if we terminate this agreement for cause.",
    },
    {
        title: "Code of Conduct",
        content: "As a member of the ACID Community Content Creator Network, you must not: <br/>(a) Violate our Community Policy, <br/>b) Engage in cheating or assist others in cheating in games, <br/>(c) Violate rules set by streaming platforms or game developers, <br/>(d) Utilize tools to artificially increase subscriber/follower count or viewership, <br/>(e) Transfer personal incentives offered through Tiers to your subscribers, or (f) Engage in any illegal activities in any jurisdiction where you access the ACID Community Content Creator Network. It is your responsibility to understand and adhere to our Community Policy, platform rules, developer regulations, and applicable laws. Ignorance is not an excuse.",
    },
    {
        title: "License and Intellectual Property Rights",
        content: "During the term of this agreement, we grant you a revocable, non-exclusive, non-transferable, worldwide, royalty-free license to use the Creative solely for fulfilling your obligations within the ACID Community Content Creator Network. You may not use the Creative for any other purpose, including creating ACID-branded merchandise.",
    },
    {
        title: "Content Ownership and Usage",
        content: "We automatically acquire rights to any content generated or made available by you incorporating the Creative (in whole or in part) (referred to as 'Content'). You hereby grant us (or any designated company) a non-exclusive, fully-paid, royalty-free, irrevocable, perpetual, transferable, sublicensable license to exploit the Content for any purpose and in any form or method in any country. We reserve the right to request removal of any Content not approved by us (at our sole discretion). Failure to comply with removal requests may result in termination of your relationship with the ACID Community Content Creator Network for cause.",
    },
    {
        title: "Feedback",
        content: "We appreciate your feedback on the Creative and the ACID Community Content Creator Network. However, by providing feedback, you automatically grant us a non-exclusive, fully-paid, royalty-free, irrevocable, perpetual, transferable, sublicensable license to exploit that feedback for any purpose and in any form or method in any country. If you cannot grant such rights under applicable law, you hereby waive and refrain from asserting those rights. We are not obligated to credit or compensate you for the use of your feedback.",
    },
    {
        title: "Intellectual Property Rights",
        content: "'Intellectual Property' encompasses trademarks, designs, copyright, database rights, software rights, domain names, and other intellectual property rights. All Intellectual Property existing before the commencement of each EIO remains with its owner. By entering this agreement, you do not acquire any title or claim to ACID Community's Intellectual Property. Similarly, we do not gain rights to any of your pre-existing property before your association with the ACID Community Content Creator Network.",
    },
    {
        title: "Revenue Share",
        content: "Revenue Share: ACID will retain 40% of the profits generated from your content.",
    },
    {
        title: "Branding Requirements",
        content: "Branding Requirements: You must prominently display the hashtag '#AcidCommunity' in the title or description of your content.",
    },
    {
        title: "Sponsorship Obligations",
        content: "Sponsorship Obligations: You are required to include ACID-sponsored banners or advertisements in your streams or videos as specified by ACID.",
    },
    {
        title: "Content Restrictions",
        content: "(1) 'Smurf' content, referring to content created using secondary or alternate accounts, is prohibited. <br/>(2) You must utilize your primary/main account for creating content. <br/>(3) Begging for donations or other forms of monetary support during streams is not allowed. <br/>(4) The use of abusive language or derogatory remarks is strictly prohibited. <br/>(5) Negative comments about the game or related content are not permitted.",
    },
    {
        title: "Acknowledgement and Acceptance",
        content: "This agreement is a legal contract and should be reviewed thoroughly. By accepting this agreement, you acknowledge understanding of its terms and agree to abide by them.",
    }
];

export default agreementSections;