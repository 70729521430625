import HeroSection from "../components/HeroSection/HeroSection";
import About from "../components/Section/AboutSection/AboutSection";
import ArticleSection from "../components/Section/ArticleSection/ArticleSection";
import GamesSection from "../components/Section/GamesSection/GamesSection";
import HorizontalContactUs from "../components/Section/HorizontalContactUs/HorizontalContactUs";
import HorizontalJoinUs from "../components/Section/HorizontalJoinUs/HorizontalJoinUs";
import Section from "../components/Section/Section";
import TeamSection from "../components/Section/TeamSection/TeamSection";
import YoutubeSection from "../components/Section/YoutubeSection/YoutubeSection";
import { useData } from "../Provider/DataContext";
import { useOutletContext } from "react-router-dom";

const Page: React.FC = () => {
  
  const { data } = useData();
  const [counter]: any = useOutletContext();

  return (
    <>
      <HeroSection Top={data?.Home.HeroSection.Top} Hedding={data?.Home.HeroSection.Hedding}  Button={data?.Home.HeroSection.Button} Link={data?.Home.HeroSection.Link} ShowButton/>
      <Section counter={counter.MySectionCounter}>
        <About />
      </Section>
      <Section counter={counter.MySectionCounter} heading={data?.Home.Games.Heading} subHeading={data?.Home.Games.Text}>
        <GamesSection />
      </Section>
      <Section counter={counter.MySectionCounter} heading={data?.Home.Team.Heading} subHeading={data?.Home.Team.SubHeading} >
        <TeamSection />
      </Section>
      <Section counter={counter.MySectionCounter}>
        <HorizontalContactUs />
      </Section>
      <Section counter={counter.MySectionCounter}>
        <ArticleSection />
      </Section>
      <Section counter={counter.MySectionCounter}>
        <HorizontalJoinUs />
      </Section>
      <Section counter={counter.MySectionCounter}>
        <YoutubeSection />
      </Section>
    </>
  )
}

export default Page;
