import React, { useState } from 'react';
import { useData } from '../Provider/DataContext';
import { MemberData } from '../Provider/MemberContext';
import { Link } from 'react-router-dom';

const BecomeMemberButton: React.FC = () => {
  const { data } = useData();
  const [member]: any = useState(MemberData);

  return (
    <>
      {member == null ? <Link className='btn text-white text-uppercase' to="join" rel="noreferrer">
        {data?.NavBar?.BecomeMember}
      </Link> : ""}
    </>
  );
};

export default BecomeMemberButton;
