export class SectionCounter {
    value:  number;
    enable: boolean

    constructor(value = 0,enable = true){
        this.value = value;
        this.enable = enable;
    }
    public get EvenSection() : boolean {
        if (!this.enable) return false;
        return this.value % 2 === 0;
    }

    public get MySectionCounter(): SectionCounter {
        return new SectionCounter(++this.value,this.enable);
    }
}
