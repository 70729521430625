import React from 'react';
import { useData } from '../../../Provider/DataContext';
import { Link } from 'react-router-dom';

const HorizontalJoinUs: React.FC = () => {
  const { data } = useData();
  return (
        <div className="container overflow-hidden">
            <div className='row justify-content-center'>
                <div className='col-7 align-self-center'>
                    <h1 className='text-uppercase'>{data?.Home.BAM.Heading}</h1>
                    <p>{data?.Home.BAM.Text}</p>
                </div>
                <div className='col-2 align-self-center'>
                    <Link to={data?.Home.BAM.Link} className='btn btn-outline-info text-uppercase'>Join US</Link>
                </div>
            </div>
        </div>
    );
};

export default HorizontalJoinUs;
