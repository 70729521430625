'use client'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { YoutubeVideo } from '../../../models/YoutubeVideo';
import { useData } from '../../../Provider/DataContext';

const YoutubeSection: React.FC = () => {

  const [videos, setVideos] = useState<YoutubeVideo[]>([]);
  const { data } = useData();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`https://raw.githubusercontent.com/ACID-DEVELOPMENT/youtubevideolist/main/README.md`);
        
        let json = response.data.replaceAll('<!-- YOUTUBE:START -->','');
        json = json.replaceAll(',<!-- YOUTUBE:END -->','');
        console.log(json);
        let list = JSON.parse(json);
        
        setVideos(list.slice(0,4));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);


  return (
    <div className="container my-5 text-uppercase">
      <div className="row justify-content-center">
        <div className="col-10 ">
          <div className="row">
            <div className="col-6 align-self-center">
              <h6 className='text-primary'>YouTube</h6>
              <h2>Videos</h2>
            </div>
            <div className="col-6 text-end align-self-center">
              <a href={data?.Youtube} className="btn btn-primary text-dark">See All</a>
            </div>
          </div>

          <div className="row mt-4">
            {videos.map((video, index) => (
              <div key={index} className="col-3">
                <img src={`https://img.youtube.com/vi/${video.url.split('v=')[1]}/0.jpg`} alt={video.title} className="img-fluid" />
                <p className='fs-5'><a className='text-white video-title' href={video.url}>{video.title}</a></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubeSection;
